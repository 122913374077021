import {isAxiosError} from 'axios'
import {getCookie} from '~/scripts/helpers'
import {bookhara, insurance, transfers, trains, wordpress} from '../../nuxt.config.js'

export default ({app, req, store}, inject) => {
	// Серверу авторизации не нравятся запросы от localhost и некоторых других источников, поэтому используем прокси для dev
	//const API_URL = process.client ? '/api' : bookhara.API_URL
	const API_URL = bookhara.API_URL
	const cookie = req ? req.headers?.cookie : document.cookie
	const userToken = getCookie('userToken', cookie)
	const insuranceToken = getCookie('insuranceToken', cookie)
	const trainToken = getCookie('trainToken', cookie)
	const language = getCookie('language', cookie)
	const header = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
	const wordpressAuthorization = process.server
		? Buffer.from(`${wordpress.USERNAME}:${wordpress.PASSWORD}`).toString('base64')
		: btoa(`${wordpress.USERNAME}:${wordpress.PASSWORD}`)


	if (language === 'ru' || language === 'uz') {
		header['Accept-Language'] = language
	}

	app.$axios.isAxiosError = isAxiosError // Добавляем в nuxt версию axios isAxiosError, который есть в обычном axios

	const api = app.$axios.create({
		baseURL: API_URL,
		withCredentials: false,
		headers: userToken
			? {
				...header,
				Authorization: 'Bearer ' + userToken
			}
			: {...header}
	})

	const insuranceApi = app.$axios.create({
		baseURL: insurance.API_URL,
		withCredentials: false,
		headers: insuranceToken
			? {
				...header,
				Authorization: 'Bearer ' + insuranceToken
			}
			: {...header}
	})

	if (insuranceToken) {
		store.commit('insurance/setToken', insuranceToken)
	}

	const transfersApi = app.$axios.create({
		baseURL: transfers.API_URL,
		withCredentials: false,
		headers: {
			...header,
			Authorization: `Bearer ${transfers.TOKEN}`
		}
	})

	const trainsApi = app.$axios.create({
		baseURL: trains.API_URL,
		withCredentials: false,
		headers: trainToken
			? {
				...header,
				Authorization: 'Bearer ' + trainToken
			}
			: {...header}
	})

	if (trainToken) {
		store.commit('trains/setToken', trainToken)
	}

	const wordpressApi = app.$axios.create({
		baseURL: process.client ? '/wordpress' : wordpress.API_URL,
		withCredentials: false,
		headers: {
			Authorization: `Basic ${wordpressAuthorization}`
		}
	})

	inject('api', api)
	inject('insuranceApi', insuranceApi)
	inject('transfersApi', transfersApi)
	inject('trainsApi', trainsApi)
	inject('wordpressApi', wordpressApi)
}
