export const state = () => {
	return {

	}
}

export const actions = {
	async getPageBySlug({commit}, slug) {
		try {
			const response = await this.$wordpressApi.get('/pages', {
				params: {
					slug,
				}
			})

			return {
				content: response.data[0]?.acf[this.$i18n.locale],
				seo: response.data[0]?.acf[`seo_${this.$i18n.locale}`]
			}
		} catch (e) {
			throw e
		}
	},

	async getCategories({commit}) {
		try {
			const categories = (await this.$wordpressApi.get('/categories')).data;
			const posts = (await this.$wordpressApi.get('/posts')).data;

			return categories.map(category => {
				return {
					id: category.id,
					name: category.acf[`category_${this.$i18n.locale}`],
					posts: posts
						.filter(post => post.categories.includes(category.id))
						.map(post => ({
							id: post.id,
							title: post.acf[`title_${this.$i18n.locale}`],
							slug: post.slug
						}))
				}
			})

			// return response.data
			// 	.filter(post => post.class_list.includes(`category-${slug}`))
			// 	.map(post => ({
			// 		id: post.id,
			// 		title: post.acf[`title_${this.$i18n.locale}`],
			// 		slug: post.slug
			// 	}))
		} catch (e) {
			throw e
		}
	},

	async getPostBySlug({commit}, slug) {
		try {
			const response = await this.$wordpressApi.get('/posts', {
				params: {
					slug,
				}
			})

			return {
				title: response.data[0]?.acf[`title_${this.$i18n.locale}`],
				content: response.data[0]?.acf[this.$i18n.locale],
				seo: response.data[0]?.acf[`seo_${this.$i18n.locale}`]
			}
		} catch (e) {
			throw e
		}
	},
}

